var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a0e3fa6ff965214ab735a1fd92434cbaff2a31ff"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

import { config } from '@/_config'

Sentry.init({
  dsn: config.SENTRY_DSN,
  environment: config.SENTRY_ENVIRONMENT,
  debug: config.DEBUG,
  enabled: config.SENTRY_ENABLED,

  tracesSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  integrations: [
    // Automatically capture breadcrumbs from console, DOM, fetch, history, and XHR
    Sentry.breadcrumbsIntegration({
      console: true,
      dom: true,
      fetch: true,
      history: true,
      xhr: true,
    }),

    // Link related errors together, providing more context in Sentry
    Sentry.linkedErrorsIntegration(),

    // Automatically handle global errors and unhandled promise rejections
    Sentry.globalHandlersIntegration({
      onerror: true,
      onunhandledrejection: true,
    }),

    // Enable performance tracing for page loads and navigation
    Sentry.browserTracingIntegration(),

    // Replays
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Added to avoid CORS problems for the browser tracing integration
  tracePropagationTargets: [
    process.env.NEXT_PUBLIC_BASE_URL || 'https://app.hellonora.ai',
    /^\/api\/v1\//,
  ],
})
